import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ConfigurationDto } from 'src/app/shared/models/configuration.model';

@Component({
  selector: 'app-transaction-ok',
  templateUrl: './transaction-ok.component.html',
  styleUrls: ['./transaction-ok.component.scss']
})
export class TransactionOkComponent implements OnInit {
  configuracion: ConfigurationDto;
  instit: string;
  valorConsulta:string='';
  captchaVal:string='';
  public currentValue: string = null;
  subscription: any;

  constructor(
    private route: ActivatedRoute,
    private configService: ConfigurationService,
    //private loginService: LoginService,
    private router: Router,
    public translate: TranslateService,
  ) { 
    let obj =this.router.getCurrentNavigation().extras.state;
   if(obj !== undefined && obj!== null ){
    this.valorConsulta = obj['val'];
   }
  }

  ngOnInit(): void {
    this.instit = this.route.snapshot.paramMap.get('instit');
    if(this.instit !== undefined && this.instit !== null && this.instit !== ''){
     this.loadConfig();
    }
    
  }

  loadConfig(){
    if(!this.configService.isLoaded(this.instit)){
      this.configService.loadConfiguration(this.instit);
      this.subscription = this.configService.getEventConfigurationRecived().subscribe(isLoaded => {
          this.configuracion = this.configService.getConfiguration();
          //this.loginService.login();
      });
    }else{
      this.configuracion = this.configService.getConfiguration();
    }
  }
  tornarInici(){
    this.router.navigateByUrl('/'+this.instit+'/dashBoard');
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }

}
