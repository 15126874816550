
<div class="col d-flex justify-content-center
flex-column min-vh-100 align-items-center">
  <div  
  [ngStyle]="{'background-color':configuracion.backgroundColor, 'color': configuracion.fontColor,'border-radius': configuracion.borderRadius }" 
  class="row col-xl-3 col-sm-12	col-md-8	col-lg-6">  
    <div  class="cabecera">
      <div style="float:right; padding-right: 1%;">
        <span class="form-inline" >
          <!-- <select 
              class="form-control" 
              #selectedLang 
              (change)="switchLang(selectedLang.value)">
            <option *ngFor="let language of translate.getLangs()" 
              [value]="language"
              [selected]="language === translate.currentLang" 
              >           
                {{ language | translate }} 
            </option>
          </select> -->
        </span>
      </div>
    </div>
    <img *ngIf="this.configuracion.logo !== undefined 
    && this.configuracion.logo !== null 
    && this.configuracion.logo !== ''" [src]="this.configuracion.logo" [ngStyle]="{'background-color':configuracion.backgroundColorLogo }">
    
       <!--
                [ngStyle]="{'background-image':'url(assets/img/'+language+'.png'}" 
                <img [src]="'assets/img/'+language+'.png'"> 
             <span  [ngStyle]="{'background-image':'url(assets/img/'+language+'.png'}"> -->

        <mat-card class="example-card" [ngStyle]="{'background-color':configuracion.backgroundColor , 'color': configuracion.fontColor,'border-radius': configuracion.borderRadius}">
            <mat-card-title>{{'anulacio' | translate }}</mat-card-title>
            <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
            <mat-card-content>
              <p>{{'label-not-found' | translate }}</p>
              
            
            </mat-card-content>
        
          </mat-card>
          
    </div>    
</div>



