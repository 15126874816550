import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'anulacions';
  constructor(
    public translate: TranslateService
  ) {
    translate.addLangs(['ca','en', 'es','fr']);
    translate.setDefaultLang('ca');
  }
}
