import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatosPago } from 'src/app/shared/models/DatosPago.model';

@Component({
  selector: 'app-pasarela-pago',
  templateUrl: './pasarela-pago.component.html',
  styleUrls: ['./pasarela-pago.component.scss']
})
export class PasarelaPagoComponent implements OnInit {
  public datos: DatosPago;
  @ViewChild('form_redsys') form_redsys;
  uploadForm: FormGroup; 
  public formGroup = this.formBuilder.group({
    Ds_SignatureVersion: '',
    Ds_MerchantParameters:'',
    Ds_Signature:''
  });
    constructor(private formBuilder: FormBuilder, private httpClient: HttpClient) { }
  
    ngOnInit(): void {
      if(this.datos === undefined || this.datos === null ){
        this.datos = new DatosPago();
      }
    
      
    }
    onSubmit() {
      this.form_redsys.nativeElement.action=this.datos.url_redsys;
      this.form_redsys.nativeElement.ds_signature_version.value =this.datos.ds_SignatureVersion;
      this.form_redsys.nativeElement.ds_parameters.value =this.datos.ds_MerchantParameters;
      this.form_redsys.nativeElement.ds_signature.value =this.datos.ds_Signature;
      this.form_redsys.nativeElement.submit();
    }
  }
  