// import { DisplayService } from './display.service';
import { LoginService } from './login.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// import { UserService } from './user.service';
import { catchError, map } from 'rxjs/internal/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
// import { EndpointsApi } from '../conifg/endpoints';
// import { LanguageService } from './language.service';

//import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor( private toastr: ToastrService,
    public translate: TranslateService
    // private institutionService: InstitutionService,
    // private userService: UserService,
    // private loginService: LoginService,
    // private displayService: DisplayService,
    // private navController: NavController,
    // private languageService: LanguageService
    //private logService: LogService
  ) { }

  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {    
    // const user = this.userService.getUser();
    // const loginData = this.loginService.getLoginInfo();
    // const insititution = this.institutionService.getInstitucion();
    
    // const headers: any = {
    //   'Content-Type': 'application/json'
    // };
    // if((!req.url.includes(EndpointsApi.getTickets) || req.url.includes(EndpointsApi.buyTicket)) && !req.url.includes(EndpointsApi.getDevolucionesAsync)
    // && !req.url.includes(EndpointsApi.getAsyncNotificaciones) && !req.url.includes('assets/i18n')
    // && !req.url.includes(EndpointsApi.helpProject.replace('{appId}',''))){
    //    this.displayService.showLoading();
    // }
    // // cambiamos la url porque de esta forma salta el show loading pero la peticion en el back es la misma
    // if(req.url.includes(EndpointsApi.getTicketsNotAsync)){
    //   req = req.clone({url : req.url.replace(EndpointsApi.getTicketsNotAsync, EndpointsApi.getTickets)});
    // }
    // if(req.url.includes(EndpointsApi.getDevolucionesAsync)){
    //   req = req.clone({url : req.url.replace(EndpointsApi.getDevolucionesAsync, EndpointsApi.getDevoluciones)});
    // }
    // if(req.url.includes(EndpointsApi.getAsyncNotificaciones)){
    //   req = req.clone({url : req.url.replace(EndpointsApi.getAsyncNotificaciones, EndpointsApi.getNotificaciones)});
    // }


    // if (loginData!== undefined && !req.url.includes( EndpointsApi.login )) {
    //   headers.User = loginData.SecureIdUser;
    // }
    // if (loginData!== undefined && !req.url.includes( EndpointsApi.login )) {
    //   headers.Authorization = 'Bearer ' + loginData.access_token;
    // }
    // headers.Locale = this.languageService.getCurrentLanguage();
    // const request = req.clone({
    //   setHeaders: JSON.parse(JSON.stringify(headers))
    // });
    return next.handle( req ).pipe(
      map( r => this.mapResponse(r) ),
      catchError( e => this.handleError(e) )
    );

  }

  handleError( error: any ) {
    //this.displayService.hideLoading();
    let nextPage: string;
    const result: { [k: string]: any } = {};
    result.status = error.status;
    let showToastError: boolean = true;
   // this.logService.writeLog(error.message);
   let message='';
    switch ( error.status ) {
      case 400: // Bad req
        break;
      case 401: // Unautorized
       this.translate.get('errorAuth').subscribe(x =>{
        message=x;
      });
        break;
      case 403: // Forbidden
        break;
      case 404: // Not found
      showToastError = false;
        break;
      case 500: // Server error
      this.translate.get('internalError').subscribe(x =>{
        message=x;
      });
        break;
      default:
        break;
    }

    if ( typeof error.error === 'string' ) {
      result.text = error.error;
    } else if ( typeof error.message === 'string' ) {
      result.text = error.message;
    }

  if(showToastError){
    this.toastr.error(message);

    
  } 

    return throwError( result );

  }

  mapResponse( res: HttpEvent<any> ): any {
   // this.displayService.hideLoading();
    return res || {};
  }
}
