<!-- <mat-card class="example-card">
    <mat-card-title>Anulació</mat-card-title>
    <mat-card-content>
        <div class="row">
        <div class="col-4">
            <span>No hi ha res!!</span>
        </div>
        

        <div class="col-12">
            <button mat-button 
            class="button100"    
            [ngStyle]="{'background-color': configuracion.buttonColor, 'color': configuracion.buttonFontColor}"
            (click)="goHome()">Tornar a l'inici</button>
        </div>
        </div>
    </mat-card-content>
</mat-card>
 -->



<div class="col d-flex justify-content-center
flex-column min-vh-100 align-items-center">
  <div  *ngIf="configuracion !== undefined" [ngStyle]="{'background-color':configuracion.backgroundColor }" class="row">
    
        <mat-card class="example-card">
            <mat-card-title>{{'notFoundTitle' | translate}}</mat-card-title>
            <mat-card-content>
                <div class="row">
                    <div class="col-3"><mat-icon>find_in_page</mat-icon></div>
                    <div class="col-9"><p> <span>{{'notFound' | translate}}</span></p></div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button 
                class="button100"    
                [ngStyle]="{'background-color': configuracion.buttonColor, 'color': configuracion.buttonFontColor}"
                (click)="goHome()">{{'tornaInici' | translate}}</button>
            </mat-card-actions>
          </mat-card>
    </div>    
</div>

