import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ConfigurationDto } from 'src/app/shared/models/configuration.model';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  configuracion: ConfigurationDto;

  constructor(    private configService: ConfigurationService,
    ) { }

  ngOnInit(): void {
    this.loadConfig();
  }
  loadConfig(){
    this.configuracion = this.configService.getConfiguration();
  }
}
