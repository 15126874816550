import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ConfigurationDto } from 'src/app/shared/models/configuration.model';
import { Denuncia } from 'src/app/shared/models/denuncia.model';

@Component({
  selector: 'app-dash-board-denuncias',
  templateUrl: './dash-board-denuncias.component.html',
  styleUrls: ['./dash-board-denuncias.component.scss']
})
export class DashBoardDenunciasComponent implements OnInit {
  configuracion: ConfigurationDto;
 denuncias: Denuncia[];
 instit: string;
  constructor(private router: Router, 
    private activatedRoute: ActivatedRoute,
    private configService: ConfigurationService,) {
    let obj =this.router.getCurrentNavigation().extras.state;
    this.denuncias = obj as Denuncia[];

    this.instit = this.activatedRoute.snapshot.paramMap.get('instit');
    if(this.instit !== undefined && this.instit !== null && this.instit !== ''){
      this.configService.loadConfiguration(this.instit);
      this.configuracion = this.configService.getConfiguration();
      
    }
  }
  ngOnInit(): void {
  //   this.activatedroute.data.subscribe(data => {
  //     this.denuncias=data as Denuncia[];
  // })
  }

}
