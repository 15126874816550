import { Denuncia } from './../../shared/models/denuncia.model';
import { LoginService } from './../../services/login.service';
import { DenunciasService } from './../../services/denuncias-service';
import { ConfigurationService } from './../../services/configuration.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationDto } from 'src/app/shared/models/configuration.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss']
})
export class DashBoardComponent implements OnInit {
  configuracion: ConfigurationDto;
  instit: string;
  valorConsulta:string='';
  captchaVal:string='';
  email: string='';
  public currentValue: string = null;
  subscription: any;
  enviarRebut:boolean=false;//test

  
  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private configService: ConfigurationService,
    private denunciesService: DenunciasService,
    private loginService: LoginService,
    private router: Router,
    public translate: TranslateService,
    private toastr: ToastrService
  ) { 
    let obj =this.router.getCurrentNavigation().extras.state;
   if(obj !== undefined && obj!== null ){
    this.valorConsulta = obj['val'];
   }
  }

  ngOnInit(): void {
    this.instit = this.route.snapshot.paramMap.get('instit');
    if(this.instit !== undefined && this.instit !== null && this.instit !== ''){
     this.loadConfig();
    }
    this.setDefaultLanguage();
    this.addRecaptchaScript();
    
  }
  setDefaultLanguage(){
    if(this.translate.currentLang === undefined || this.translate.currentLang === null ||this.translate.currentLang ===''){
      this.translate.use('ca');
    }
  }
    loadConfig(){
    if(!this.configService.isLoaded(this.instit)){
      this.configService.loadConfiguration(this.instit);
      this.subscription = this.configService.getEventConfigurationRecived().subscribe(isLoaded => {
          this.configuracion = this.configService.getConfiguration();
          this.loginService.login();
      });
    }else{
      this.configuracion = this.configService.getConfiguration();
    }
  }
  consultar(){
    let val= this.valorConsulta;
    if(this.enviarRebut && (this.email ===null || this.email ===undefined||this.email ==='' )){
      this.translate.get('errorNeedEmail').subscribe(x =>{
        this.toastr.error(x);
      });
      return;
    }
    if(this.captchaVal !== undefined && this.captchaVal !== undefined && this.captchaVal !== '' ){
      this.denunciesService.getAnulaciones(val, this.captchaVal).subscribe(x =>{
        if(x !== undefined && x !== null && x.status === "OK" 
          && x.result !== undefined && x.result !== null
          && x.result.length >0 ){
            let deuncias: Denuncia[] = x.result;
            if(this.email !== undefined && this.email !== null && this.email !== '' ){
              deuncias.forEach(den =>{
                den.correu = this.email;
              });
            }
            this.router.navigateByUrl('/'+this.instit+'/dashBoardDenuncias', { state: deuncias });
        }else{          
            this.router.navigateByUrl('/'+this.instit+'/denunciasNotFound', { state: {val} });
        }
      });
    }else{
      this.translate.get('errorAuth').subscribe(x =>{
      this.toastr.error(x);
      });
    }
   
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }
  setLanguage( lang: string){
    this.switchLang(lang);
  }
  //captcha
  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : this.configuracion.keyCaptcha,
      'callback': (response) => {
          this.captchaVal = response;
      }
    });
  }
  resetReCaptch() {
    window['grecaptcha'].reset();
  }
  addRecaptchaScript() {
 
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
 
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
 
  }

  changeStatusEnviarRebut(){
    this.enviarRebut = !this.enviarRebut;
    if(!this.enviarRebut){
      this.email ='';
    }
  }

  
}
