
<div class="col d-flex justify-content-center
flex-column min-vh-100 align-items-center">
  <div  
  [ngStyle]="{'background-color':configuracion.backgroundColor, 'color': configuracion.fontColor,'border-radius': configuracion.borderRadius }" 
  class="row col-xl-3 col-sm-12	col-md-8	col-lg-6">  
    <div  class="cabecera">
      <div style="float:right; padding-right: 1%;">
        <span class="form-inline" >
          <div class="tab" [ngStyle]="{'color':'#9b8888 !important','border-radius': configuracion.borderRadius }" >
            <!-- <button class="tablinks" onclick="openCity(event, 'London')">Català</button>
            <button class="tablinks" onclick="openCity(event, 'Paris')">Español</button>
            <button class="tablinks" onclick="openCity(event, 'Tokyo')">English</button>  -->
            <!-- <button *ngFor="let language of translate.getLangs()" onclick="setLanguage(event, language)"  [ngSwitch]="language">       -->
             <button *ngFor="let language of translate.getLangs()" (click)="setLanguage( language)"
              [ngClass]="(translate.currentLang  !== undefined && translate.currentLang !== null && translate.currentLang !== '' &&
              language === translate.currentLang) ? 'buttonSelected' : 'buttonNotSelected'">            
              <!-- <img  *ngSwitchCase="'ca'" [src]="'../assets/img/ca.png'"  > 
              <img  *ngSwitchCase="'es'" [src]="'../assets/img/es.png'"  >  -->
              <img  src='{{"../assets/img/"+language+".png"}}' > 
              <!-- {{'Clear ' + myVar.id}} -->
               {{ language | translate }} 
            </button>
          </div>
          <!-- <select 
              class="form-control" 
              #selectedLang 
              (change)="switchLang(selectedLang.value)">
            <option *ngFor="let language of translate.getLangs()" 
              [value]="language"
              [selected]="language === translate.currentLang" 
              >           
                {{ language | translate }} 
            </option>
          </select> -->
        </span>
      </div>
    </div>
    <img *ngIf="this.configuracion.logo !== undefined 
    && this.configuracion.logo !== null 
    && this.configuracion.logo !== ''" [src]="this.configuracion.logo" [ngStyle]="{'background-color':configuracion.backgroundColorLogo }">
    
       <!--
                [ngStyle]="{'background-image':'url(assets/img/'+language+'.png'}" 
                <img [src]="'assets/img/'+language+'.png'"> 
             <span  [ngStyle]="{'background-image':'url(assets/img/'+language+'.png'}"> -->

        <mat-card class="example-card" [ngStyle]="{'background-color':configuracion.backgroundColor , 'color': configuracion.fontColor,'border-radius': configuracion.borderRadius}">
            <mat-card-title>{{'anulacio' | translate }}</mat-card-title>
            <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
            <mat-card-content>
              <p>{{'label-intro-dash' | translate }}</p>
              
              <mat-form-field class="example-form-field" appearance="fill" >
                <mat-label  [ngStyle]="{'color': configuracion.fontColor}">{{'matricula/anulacio' | translate }}</mat-label>
                 <input matInput type="text" [(ngModel)]="this.valorConsulta"   [ngStyle]="{'color': configuracion.fontColor}" > 
                
                <button *ngIf="valorConsulta" matSuffix mat-icon-button aria-label="Clear" (click)="valorConsulta=''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

<!--novetat-->
<div class="row">
  <!-- <div class="col-4  ">
    <mat-form-field  class="example-form-field" appearance="fill" >
      <mat-label  [ngStyle]="{'color': configuracion.fontColor}">{{'Enviar rebut' | translate }}</mat-label>
      <input  type="checkbox"  (change)="changeStatusEnviarRebut()" [checked]="this.enviarRebut">
    </mat-form-field>
  </div>   -->
  <div class="col-12  ">
    <!-- <label > 
      <input  type="checkbox"  class="form-check-input" (change)="changeStatusEnviarRebut()" [checked]="this.enviarRebut">
      Enviar rebut
    </label> -->
    <mat-card-title >
      <input  type="checkbox"  class="form-check-input" (change)="changeStatusEnviarRebut()" [checked]="this.enviarRebut">
      <label style="margin-top: 6px;" >&nbsp;{{'Enviar rebut' | translate }}</label> 
    </mat-card-title>     
    <mat-form-field  class="example-form-field" appearance="fill" >
      <mat-label  [ngStyle]="{'color': configuracion.fontColor}">{{'e-mail' | translate }}</mat-label>
       <input matInput type="text" [(ngModel)]="this.email"  [disabled]="!this.enviarRebut" [ngStyle]="{'color': configuracion.fontColor}" > 
      
      <button *ngIf="email" matSuffix mat-icon-button aria-label="Clear" (click)="email=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>  
</div>
<!--end novetat-->
              <div class="row">
                <div class="col-12  ">
                  <div #recaptcha style="padding-top: 15px;" ></div>
                </div>  
              </div>
            </mat-card-content>
            <mat-card-actions>
              <!-- [disabled]="this.valorConsulta === undefined || this.valorConsulta === ''"  -->
              <button mat-button  [disabled]="this.valorConsulta === undefined || this.valorConsulta === ''"
              class="button100"  [ngStyle]="{'border-color':configuracion.buttonColor, 'color': configuracion.buttonFontColor}" 
              (click)="consultar()">{{'seguent' | translate }}</button>
            </mat-card-actions>
          </mat-card>
          
    </div>    
</div>

