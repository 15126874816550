import { LoginService } from './services/login.service';
import { HttpClient, HttpClientModule, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpSecureClient } from './services/httpSecure.service';
import { DenunciasService } from './services/denuncias-service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashBoardComponent } from './sections/dash-board/dash-board.component';
import { NotFoundComponent } from './sections/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigurationService } from './services/configuration.service';
import { CommonModule, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { CardDenunciaComponent } from './sections/card-denuncia/card-denuncia.component';
import { DashBoardDenunciasComponent } from './sections/dash-board-denuncias/dash-board-denuncias.component';
import { DenunciesNotFoundComponent } from './sections/denuncies-not-found/denuncies-not-found.component'
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PasarelaPagoComponent } from './sections/pasarela-pago/pasarela-pago.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { TransactionOkComponent } from './sections/transaction-ok/transaction-ok.component';
import { TransactionKOComponent } from './sections/transaction-ko/transaction-ko.component';
@NgModule({
  declarations: [
    AppComponent,
    DashBoardComponent,
    NotFoundComponent,
    CardDenunciaComponent,
    DashBoardDenunciasComponent,
    DenunciesNotFoundComponent,
    PasarelaPagoComponent,
    TransactionOkComponent,
    TransactionKOComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    //MatDialogModule,  
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,  
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot()
  ],
  providers: [ConfigurationService, DenunciasService, HttpSecureClient,HttpClient, LoginService, DatePipe,{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
