import { Denuncia } from './../shared/models/denuncia.model';
import { LoginService } from './login.service';
import { ConfigurationDto } from 'src/app/shared/models/configuration.model';

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// import { config } from 'src/app/utils/configurations/config';
import { Config } from "protractor";
import { HttpSecureClient } from "./httpSecure.service";
import { ConfigurationService } from "./configuration.service";
import { api } from '../utils/configuration/urls';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable()
export class DenunciasService {
    currentConfig: ConfigurationDto;
    subscription: any;
    constructor(private http: HttpSecureClient,
        private configService: ConfigurationService, 
        private loginService: LoginService,
        public datepipe: DatePipe) {
            // this.configService.configLoaded.subscribe( value => {
            //     if (value === true) {
            //         this.currentConfig =  this.configService.getConfiguration();
            //     }
            // });
            this.subscription = this.configService.getEventConfigurationRecived().subscribe(isLoaded => {
                this.currentConfig = this.configService.getConfiguration();
            });
         }

    getAnulaciones(anul: string, captcha:string):Observable<any> {
        const dateStr = this.datepipe.transform(new Date(), 'yyyyMMddHHmmss');
        let objParams  =new HttpParams()
        .append('codAnulacion', anul)
        .append('fechaAnulacion', dateStr)
        .append('captcha', captcha);
  
        return this.http.get(this.currentConfig.urlWs + api.endpoints.urlAnulaciones, objParams);
    }
    getDatosPago(denuncia: Denuncia):Observable<any>{
        //return this.http.post(this.currentConfig.urlWs + api.endpoints.urlAnulaciones, denuncia);
        
    return this.http.post(this.currentConfig.urlWs + api.endpoints.getDatosPago, denuncia).pipe( map( res => {
        return res;
      }));
    }
}