import { TransactionKOComponent } from './sections/transaction-ko/transaction-ko.component';
import { TransactionOkComponent } from './sections/transaction-ok/transaction-ok.component';
import { DenunciesNotFoundComponent } from './sections/denuncies-not-found/denuncies-not-found.component';
import { DashBoardDenunciasComponent } from './sections/dash-board-denuncias/dash-board-denuncias.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashBoardComponent } from './sections/dash-board/dash-board.component';
import { NotFoundComponent } from './sections/not-found/not-found.component';


const routes: Routes = [
  { path: 'validacionPago', component: NotFoundComponent },
  { path: ':instit/dashBoard', component: DashBoardComponent },
  { path: ':instit', component: DashBoardComponent },
  { path: ':instit/dashBoardDenuncias', component: DashBoardDenunciasComponent },
  { path: ':instit/denunciasNotFound', component: DenunciesNotFoundComponent },
  { path: ':instit/transactionOk', component: TransactionOkComponent },
  { path: ':instit/errortransaction', component: TransactionKOComponent },
  { path: '', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
