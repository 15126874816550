<mat-card class="example-card">
    <mat-card-title>{{'anulacio' | translate }}</mat-card-title>
    <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
    <mat-card-content>
        <div class="row">
        <div class="col-4">
            <span>{{'Matricula' | translate }}</span>
        </div>
        <div class="col-8">
            <span>{{denuncia.matricula}}</span>
        </div>

        <div class="col-4">
            <span>{{'Núm Boletin' | translate }}</span>
        </div>
        <div class="col-8">
            <span>{{denuncia.numBoletin}}</span>
        </div>

        <div class="col-4">
            <span>{{'Cod. Anulación' | translate }}</span>
        </div>
        <div class="col-8">
            <span>{{denuncia.codAnulacion}}</span>
        </div>

        <div class="col-4">
            <span>{{'Fecha/hora infracción' | translate }}</span>
        </div>
        <div class="col-8">
            <span>{{denuncia.fechaInfraccion | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
        </div>

        <div class="col-4">
            <span>{{'Anulable hasta' | translate }}</span>
        </div>
        <div class="col-8">
            <span>{{denuncia.fechaLimiteAnulacion | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
        </div>

        <div class="col-4">
            <span>{{'Ubicación' | translate }}</span>
        </div>
        <div class="col-8">
            <span>{{denuncia.ubicacion}}</span>
        </div>

        <div class="col-4">
            <span>{{'Infracción' | translate }}</span>
        </div>
        <div class="col-8">
            <span>{{denuncia.infraccion.descripcion}}</span>
        </div>

        <div class="col-4">
            <span>{{'Importe anulación' | translate }}</span>
        </div>
        <div class="col-8">
            <span *ngIf="denuncia.precio!==-1">{{denuncia.precio/100}}</span>
            <span *ngIf="denuncia.precio===-1">Denuncia no anulable</span>
        </div>

        <div class="col-12">
            <button mat-button [disabled]="denuncia.precio===-1"
            class="button100"    
            [ngStyle]="{'background-color':denuncia.precio===-1 ? 'black' : configuracion.buttonColor,
            'color': configuracion.buttonFontColor}"
            
            (click)="pagar()">{{'Pagar' | translate }}</button>
        </div>
        </div>
    </mat-card-content>
</mat-card>
<app-pasarela-pago hidden="true"></app-pasarela-pago>