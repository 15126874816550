<div class="col d-flex justify-content-center
flex-column min-vh-100 align-items-center">
  <div  
  [ngStyle]="{'background-color':configuracion.backgroundColorError, 'color': configuracion.fontColor,'border-radius': configuracion.borderRadius }" 
  class="row col-xl-3 col-sm-12	col-md-8	col-lg-6">  
    <div  class="cabecera">
      <div style="float:right; padding-right: 1%;">
        <span class="form-inline" >
          <select 
              class="form-control" 
              #selectedLang 
              (change)="switchLang(selectedLang.value)">
            <option *ngFor="let language of translate.getLangs()" 
              [value]="language"
              [selected]="language === translate.currentLang" 
              >           
                {{ language | translate }} 
            </option>
          </select>
        </span>
      </div>
    </div>
    <img *ngIf="this.configuracion.logo !== undefined 
    && this.configuracion.logo !== null 
    && this.configuracion.logo !== ''" [src]="this.configuracion.logo" [ngStyle]="{'background-color':configuracion.backgroundErrorColorLogo }">
    
        <mat-card class="example-card" [ngStyle]="{'background-color':configuracion.backgroundColorError , 'color': configuracion.fontColor,'border-radius': configuracion.borderRadius}">
            <mat-card-title>{{'title-trans-ko' | translate }}</mat-card-title>
            <mat-card-content>
              <p>{{'label-trans-ko' | translate }}</p>
              
              
            </mat-card-content>
            <mat-card-actions>
             
              <button mat-button   class="button100"  [ngStyle]="{'border-color':configuracion.buttonColor, 'color': configuracion.buttonFontColor}" 
              (click)="tornarInici()">{{'tornarInici' | translate }}</button>
            </mat-card-actions>
          </mat-card>
          
    </div>    
</div>