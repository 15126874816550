import { Injectable, Injector, Inject} from '@angular/core';
import { HttpClient , HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from './loaderService';
import { ConfigurationDto } from '../shared/models/configuration.model';
import { ConfigurationService } from './configuration.service';
import * as querystring from 'querystring';


@Injectable()
export class HttpSecureClient {
  //private baseUrl: string;
  private currentConfig: ConfigurationDto;
    
  constructor(
    private http: HttpClient,
    private configService: ConfigurationService,
  ) {
    this.currentConfig = this.configService.getConfiguration();
   }


   createAuthorizationHeader(hide = false) {
      const token = sessionStorage.getItem('access_token');
      if (token !== undefined && token !== null  ) {
        return new HttpHeaders()
        .append('Authorization', 'Bearer ' + token)
        .append('X-Origin', 'SomDenWeb')
        .append('Allow-Control-Allow-Origin', '*')
        .append('content-type', 'application/json')
        .append(hide ? 'hideProgress' : 'void', '');
      } else {
        return null;
      }

    }

  protected getParams( params: any = {} ): HttpParams {
    return new HttpParams({
      fromObject: params
    });
  }
  public getWithoutParams( route: string, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.get(route, options );

  }
  public getLogin( route: string, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.get(route, options );

  }
  get(url: string, params: HttpParams, hide = false) {
    return this.http.get(url.replace('{hostname}', window.location.hostname), {params: params, headers: this.createAuthorizationHeader(hide) });
  }

  public getWithFilter( route: string, params: any ): Observable<any> {

    return this.http.get(route, params );

  }

  public getWithUrlParams( route: string, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.get(route, options );

  }

  public delete( route: string, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.delete(route, options );

  }

  public post( route: string, body: any, params?: any ): Observable<any> {

    const options = { params: this.getParams( params ), headers: this.createAuthorizationHeader(false) };
    return this.http.post( route, body , options );

  }

  public loginPost( route: string, body: any ): Observable<any> {
    const bodyString = querystring.stringify(body);
    return this.http.post( route, bodyString );
  };

  public put( route: string, body: any, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.put(route, body, options );

  }

  public path( route: string, body: string, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.patch(route, body, options );

  }
  private handleError(error: Response | any ) {

    // let errorMessage = 'Ooops';
    // let redirectToLogin = false;
    // console.log(error);
    // switch (error.status) {
    //   case 0:
    //     errorMessage = this.i18n('El servidor està desconnectat');
    //     break;
    //   // client errors
    //   case 400:
    //     errorMessage = this.i18n('Petició incorrecte');
    //     break;
    //   case 401:
    //     redirectToLogin = true;
    //     errorMessage = this.i18n('No autoritzat');
    //     break;
    //   case 403:
    //     redirectToLogin = true;
    //     errorMessage = this.i18n('Accés prohibit');
    //     break;
    //   case 404:
    //     errorMessage = this.i18n('No trobat');
    //     break;
    //   case 408:
    //     errorMessage = this.i18n('Temps exhaurit');
    //     break;
    //   case 409:
    //     errorMessage = this.i18n('Element repetit');
    //     break;
    //   // server errors
    //   case 500:
    //     errorMessage = this.i18n('Error intern en el servidor');
    //     break;
    //   case 503:
    //     errorMessage = this.i18n('Servei no disponible');
    //     break;
    // }

    // if (error != null) {
    //   let errorText = '';
    //   if (typeof(error.error) === 'string') {
    //     errorText = error.error;
    //   } else if (error.status === 406 && typeof(error.error) === 'object') {
    //     errorText = this.i18n('Error descarregant el fitxer:\n El fitxer és massa gran. \nContacti amb informàtica.');
    //   }

    //   this.toastr.error(errorText, errorMessage, {
    //     closeButton: true
    //   });

    //   if (redirectToLogin) {
    //     this.router.navigate(['/']);
    //   }
    // }

    return Observable.throw(Error);
  }
}