import { DenunciasService } from './../../services/denuncias-service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ConfigurationDto } from 'src/app/shared/models/configuration.model';
import { Denuncia } from 'src/app/shared/models/denuncia.model';
import { PasarelaPagoComponent } from '../pasarela-pago/pasarela-pago.component';

@Component({
  selector: 'app-card-denuncia',
  templateUrl: './card-denuncia.component.html',
  styleUrls: ['./card-denuncia.component.scss']
})
export class CardDenunciaComponent implements OnInit {
  
  @Input() denuncia: Denuncia;
  configuracion: ConfigurationDto;
  instit: string;
  subscription: any;
  @ViewChild(PasarelaPagoComponent) pasarelaPago: PasarelaPagoComponent;
  
  constructor( 
    private route: ActivatedRoute,
    private configService: ConfigurationService,
    private denunciasService: DenunciasService) { }

  ngOnInit(): void {
    this.instit = this.route.snapshot.paramMap.get('instit');
    if(this.instit !== undefined && this.instit !== null && this.instit !== ''){
      // this.configService.loadConfiguration(this.instit);
      // this.configuracion = this.configService.getConfiguration();
      this.loadConfig();
    }
  }
  loadConfig(){
    if(!this.configService.isLoaded(this.instit)){
      this.configService.loadConfiguration(this.instit);
      this.subscription = this.configService.getEventConfigurationRecived().subscribe(isLoaded => {
          this.configuracion = this.configService.getConfiguration();
      });
    }else{
      this.configuracion = this.configService.getConfiguration();
    }
  }
  pagar(){
    this.denunciasService.getDatosPago(this.denuncia).subscribe(x =>{
      if(x !== undefined && x !== null ){
        if( //x.resultadoPrePago === ResultadoPrePagoEnum.OK &&
          x.result !== undefined && x.result !== undefined //&&
           //x.result.datosRedsys !== undefined && x.result.datosRedsys !== null
           ){
        
          this.pasarelaPago.datos=x.result;
          this.pasarelaPago.onSubmit();
          


        }else{
          let message='';
          // switch (x.resultadoPrePago) {
          //   case ResultadoPrePagoEnum.ERROR_MATRICULAS_ESPECIALES: {
          //     message = MensajeResultadoPrePagoEnum.ERROR_MATRICULAS_ESPECIALES
          //     break;
          //   }
          //   case ResultadoPrePagoEnum.ERROR_WS_AYUNTAMIENTO: {
          //     message = MensajeResultadoPrePagoEnum.ERROR_WS_AYUNTAMIENTO
          //     break;
          //   }
          //   case ResultadoPrePagoEnum.ERROR_WS_SOMINTEC: {
          //     message = MensajeResultadoPrePagoEnum.ERROR_WS_SOMINTEC
          //     break;
          //   }
          //   case ResultadoPrePagoEnum.ERRROR_PADRON: {
          //     message = MensajeResultadoPrePagoEnum.ERRROR_PADRON
          //     break;
          //   }
          //   case ResultadoPrePagoEnum.ERROR_BONO_ACTIVO_O_HAN_PASADO_MAS_DE_15_DIAS: {
          //     message = MensajeResultadoPrePagoEnum.ERROR_BONO_ACTIVO_O_HAN_PASADO_MAS_DE_15_DIAS
          //     break;
          //   }
          //   case  ResultadoPrePagoEnum.ERROR_VALIDACION_CAPTCHA: {
          //     message = MensajeResultadoPrePagoEnum.ERROR_VALIDACION_CAPTCHA
          //     this.resetReCaptch();
          //     break;
          //   }
          //   default: {
          //     message = MensajeResultadoPrePagoEnum.ERROR_DESCONOCIDO
          //     break;
          //   }
          // } 
          //this.toastr.warning(message, 'ERROR');
        }
      }
    });
  }
}
