
import { ConfigurationService } from './configuration.service';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Observable } from 'rxjs/Observable';
import { throwError, Subject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as querystring from 'querystring';
//import { api } from '../shared/configuration/urls';
import { api } from '../utils/configuration/urls';
//import { ToastrService } from 'ngx-toastr';
//import { StartupService } from '../../../shared/services/startup/startup.service';
//import { CookieService } from 'ngx-cookie-service';
import { LoginModel } from '../shared/models/login.model';
import { ConfigurationDto } from '../shared/models/configuration.model';
import { HttpSecureClient } from './httpSecure.service';
import { LoginResponse } from '../shared/models/loginResponse.model';
import { StorageKeys } from '../utils/configuration/storage';
import { LoginStatusResponse } from '../shared/models/loginStatusResponse.model';

@Injectable()
export class LoginService {
  loginFinished: Subject<boolean>;
  public wongInstitutionDetected = new EventEmitter();
  private currentConfig: ConfigurationDto;
  constructor(
    private http: HttpSecureClient,
    //private cookieService: CookieService,
    private configService: ConfigurationService,
    //sprivate toastr: ToastrService,
  ) {
    this.loginFinished = new Subject<boolean>();
    this.loginFinished.next(false);
    this.currentConfig = this.configService.getConfiguration();
  }

  
  // private handleError(error: any) {
  //   switch (error.status) {
  //     case 404:
  //       this.toastr.error(  
  //         //TODO transalte
  //         'Institució incorrecte!');
  //       this.wongInstitutionDetected.emit(true);
  //       break;
  //     default:
  //     this.toastr.error(
  //       //TODO transalte
  //       'No hi ha connexió o s\'ha produït un error en el servidor!');
  //   }
  // }

  // public login(): void { }
  public login() {
    let ret ;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    });
    let login: LoginModel= new LoginModel();
    if(this.currentConfig === undefined || this.currentConfig === null){
      this.currentConfig = this.configService.getConfiguration();
    }
    login.password= this.currentConfig.pwd;
    login.username= this.currentConfig.usr;
    this.http.getLogin(this.currentConfig.urlWs +api.endpoints.urlLogin,login).subscribe(( res: LoginStatusResponse) => {
        //ret = res as LoginResponse;        
    //  // ret.SecureIdInstit= secureIdInstitucion;
      const tokenExpiration = new Date();
       tokenExpiration.setSeconds( tokenExpiration.getSeconds() + Number.parseFloat(res.result.expiresIn) );
    //   ret.SecureIdUser = res.sid;
    sessionStorage.setItem('access_token', res.result.accessToken);
    
    
    //   this.storageService.set( StorageKeys.password , pwd );
    //   this.loginData = ret;
    //   this.isUserLogged = true;
    });
    
  }
  // setCredentials(token: string, expires_in: any, instit: string): boolean {
  //   try {
  //     sessionStorage.setItem('access_token', token);
  //     localStorage.setItem('access_token', token);
  //     const dt = new Date().getTime() + expires_in * 1000;
  //     sessionStorage.setItem('expiration_date', dt.toString());
  //     sessionStorage.setItem('IdInstitucion', instit);
  //     localStorage.setItem('IdInstitucion', instit);
  //     //this.cookieService.set('InstitucioSeleccionada', instit);
  //     return true;
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //     // expected output: ReferenceError: nonExistentFunction is not defined
  //     // Note - error messages will vary depending on browser
  //   }


  // }
  loginComplete(): any {
    this.loginFinished.next(true);
  }

}
