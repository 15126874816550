import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ConfigurationDto } from 'src/app/shared/models/configuration.model';

@Component({
  selector: 'app-denuncies-not-found',
  templateUrl: './denuncies-not-found.component.html',
  styleUrls: ['./denuncies-not-found.component.scss']
})
export class DenunciesNotFoundComponent implements OnInit {
  valor:string='';
  instit: string;
  configuracion: ConfigurationDto;
   constructor(private router: Router, 
     private activatedRoute: ActivatedRoute,
     private configService: ConfigurationService,) {
    //valor
    let obj =this.router.getCurrentNavigation().extras.state;
    this.valor= obj['val'];
 
     this.instit = this.activatedRoute.snapshot.paramMap.get('instit');
     if(this.instit !== undefined && this.instit !== null && this.instit !== ''){
      this.configService.loadConfiguration(this.instit);
      this.configuracion = this.configService.getConfiguration();
       
     }
   }

  ngOnInit(): void {
  }
  goHome(){
    let val = this.valor;
    this.router.navigateByUrl('/'+this.instit+'/dashBoard', { state: {val} });
  }

}
