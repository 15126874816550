export const api = {
    endpoints: {
        //CAPTCHA
        urlGetConfigurationCaptcha: '/GetConfigurationCaptcha',
        
        // SERVICIOS
        urlLogin: '/api/Auth/Login',
        urlAnulaciones:'/api/Anulaciones/ConsultaAnulacionCompleta',
        getDatosPago:'/api/Cobros/GetDatosPago'
    }
}