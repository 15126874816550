<div class="col d-flex justify-content-center
 flex-column min-vh-100 align-items-center">
    
    <div *ngIf="this.denuncias.length>1">
        <div class="col-6" *ngFor="let den of this.denuncias">
            <app-card-denuncia [denuncia]="den"></app-card-denuncia>
        </div>
    </div>
    <div *ngIf="this.denuncias.length===1">
        
            <app-card-denuncia [denuncia]="this.denuncias[0]"></app-card-denuncia>
        
    </div>

</div>