import { HttpClient } from '@angular/common/http';

import { EventEmitter, Injectable, Output } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
// import { HttpSecureClient } from "../shared/http-secure-client";
// import { config } from 'src/app/utils/configurations/config';
// import { api } from 'src/app/utils/configurations/urls';
import { Config } from "protractor";
import { ConfigurationDto } from "../shared/models/configuration.model";

@Injectable({
    providedIn: 'root',
   })
export class ConfigurationService {
     config:ConfigurationDto ;
     currentInstit:string;
     configLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
     @Output() configRecived = new EventEmitter<boolean>();
    constructor(
        //private http: HttpSecureClient
        private httpClient: HttpClient
        ) { }
// load(){
//     const jsonFile = '../../assets/instit/instit/'+idIns+'.json';

//     return new Promise<void>((resolve, reject) => {
//         this.httpClient.get(jsonFile).toPromise().then((response: IConfig) => {
//             Config.settings = <IConfig>response;
//             resolve();
//         }).catch((response: any) => {
//             reject(`Error en archivo de configuracion '${jsonFile}': ${JSON.stringify(response)}`);
//         });
//     });
//}
    isLoaded(idIns):boolean{
        if(this.currentInstit===undefined 
            || this.currentInstit===null
            || this.currentInstit===''
            || this.currentInstit!==idIns){
            return false;
        }else{
            return true;
        }
    }
    loadConfiguration(idIns: string){

        let _jsonURL = '../../assets/instit/'+idIns+'.json';
        this.httpClient.get(_jsonURL).subscribe((data: ConfigurationDto) =>{
            this.config = data;
            this.configRecived.emit(true);
            this.currentInstit=idIns;
            //return this.config;
          });

        // this.config = new ConfigurationDto();
        // this.config.backgroundColor='#800000';
        // this.config.buttonColor='#800000';
        // this.config.buttonFontColor='#ffffff';
        // this.config.usr='testGranollers';
        // this.config.pwd='guapo';
        // this.config.urlWs='https://granollers.somintecsystems.com:5000'
        //this.config.urlWs='https://localhost:44301';
        
    }
    getEventConfigurationRecived() {
        return this.configRecived;
      }
    getConfiguration():ConfigurationDto{
        return this.config;
    }
}